import * as Types from "../type/Types";

const initialState = {
  campaignList: [],
  isPageLoad: false,
  campaignDetails: null,
  driverDetails: null,
  driverDetailsMap: null,
};
const CampaignReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Types.IS_PAGE_LOAD:
      return {
        ...state,
        isPageLoad: action.payload,
      };
    case Types.CAMPAIGN_LIST:
      return {
        ...state,
        campaignList: action.payload,
      };
    case Types.CAMPAIGN_DETAILS:
      return {
        ...state,
        campaignDetails: action.payload,
      };
    case Types.DRIVER_DETAILS:
      return {
        ...state,
        driverDetails: action.payload,
      };
    case Types.CAMPAIGN_DETAILS_MAP:
      return {
        ...state,
        driverDetailsMap: action.payload,
      };
    default:
      break;
  }
  return newState;
};
export default CampaignReducer;
