import { combineReducers } from "redux";
import AuthReducer from "../../modules/auth/_redux/AuthReducer";
import CampaignReducer from "../../modules/campaign/_redux/campaignReducer/CampaignReducer";
import Dashboard from "../../views/dashboard/redux/dashboardReducer";

// combine all of the reducers here
const rootReducer = combineReducers({
  authInfo: AuthReducer,
  campaignInfo: CampaignReducer,
  dashboard: Dashboard,
});

export default rootReducer;
